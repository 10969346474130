import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '@/store/reducer'
import { actionCreators } from '../store'

function useFetchList() {
  const newsList = useSelector((state: AppState) => state.news.newsList)
  const pageInfo = useSelector((state: AppState) => state.news.newsListPageInfo)
  const [loading, setLoading] = useState(false)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    category: 'all',
  })
  const dispatch = useDispatch()

  const changeQueryParams = useCallback(
    (params: Partial<typeof queryParams>) => {
      setQueryParams((q) => ({ ...q, ...params }))
    },
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(actionCreators.getNewsList(queryParams))
      setLoading(false)
    }
    fetchData()
  }, [queryParams, dispatch])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, [newsList])

  return {
    data: newsList,
    page: pageInfo,
    loading,
    changeQueryParams,
  }
}

export default useFetchList
