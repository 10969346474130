import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss'

export default function Banner(props) {
  const { banners } = props
  const [index, setIndex] = useState(1)

  useEffect(() => {
    setTimeout(() => {
      setIndex(0)
    }, 100)
  }, [])

  const lists = banners.map((one) => (
    <div key={one.id}>
      <div className={styles.CompanyBanner}>
        <img src="https://tc-cdn.123u.com/official-website/mobile/company/banner-mobile.png" />
        <div
          className={
            index === 0
              ? `${styles.BannerActive} ${styles.BannerItem}`
              : `${styles.BannerItem}`
          }
        >
          <h3>{one.titleEn}</h3>
          <h3>{one.title}</h3>
          <p>{one.desc}</p>
        </div>
      </div>
    </div>
  ))
  return <div className={styles.BannerContainer}>{lists}</div>
}

Banner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
}
