import React from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import MogulPerson from '../MogulPerson'

export default function MogulMob(props) {
  const { users } = props

  const itemList = users.map((one) => (
    <div key={one.id} className={styles.MogulPersonMob}>
      <MogulPerson personInfo={one}></MogulPerson>
    </div>
  ))
  return (
    <div className={styles.MogulPersonContainerMob}>
      {itemList}
    </div>
  )
}

MogulMob.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
}
