import React from 'react'
import PropTypes from 'prop-types'
import Banner from '@/components/Banner'
import { useSelector } from 'react-redux'
import { isWXBrowser } from '@/utils'
import { NotificationFilled } from '@ant-design/icons'
import { useHistory } from 'react-router'
import NewsIntro from './components/NewsIntro'
import BottomNavigator from './components/BottomNavigator'

import styles from './style.module.scss'

function Home() {
  const history = useHistory()

  const bannerList = [
    {
      tabTitle: '英雄杀小游戏',
      type: 'video',
      url: 'https://tc-cdn.123u.com/official-website/home/home_video_v5.mp4',
      poster: 'https://tc-cdn.123u.com/official-website/home/poster_v5.png',
      content: (
        <div className={styles.Slogan}>
          <div className={styles.SloganChinese}>让更多人发现人生的美好</div>
          <div className={styles.SloganEnglish}>discover happiness</div>
        </div>
      ),
    },
  ]
  const mobileBannerList = [
    {
      tabTitle: '英雄杀小游戏',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/mobile/home/banner.png',
    },
  ]

  const menuList = useSelector((state) => state.common.menuList)

  const testIsWxBrowser = isWXBrowser()

  return (
    <>
      {!testIsWxBrowser && (
        <div className={styles.PCBanner}>
          <Banner bannerList={bannerList} />
        </div>
      )}
      <div className={styles.MobileBanner}>
        <Banner bannerList={mobileBannerList} />
      </div>
      {/* <NewsIntro /> */}
      <BottomNavigator menuList={menuList} accordion />
      {/* <div
        className={styles.Notice}
        onClick={() => {
          history.push(`/news/116`)
        }}
      >
        <NotificationFilled className={styles.Icon} />
        关于近期不法分子攀附我司商誉从事违法行为的重要声明
      </div> */}
    </>
  )
}

Home.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Home)
