import React from 'react'
import PropTypes from 'prop-types'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { renderRoutes } from 'react-router-config'
import styles from './style.module.scss'

function CampusLayout(props) {
  const { route } = props
  const menuList = [
    {
      name: '首页',
      path: '/campus/CampusRecruit',
    },
    {
      name: '公司官网',
      path: '/',
    },
  ]
  return (
    <>
      <div className={styles.Header}>
        <Header menuList={menuList} />
      </div>
      {renderRoutes(route.routes)}
      <div className={styles.Footer}>
        <Footer />
      </div>
    </>
  )
}

CampusLayout.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) }).isRequired,
}

export default React.memo(CampusLayout)
