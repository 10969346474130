import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Spin } from 'antd'
import { useRequest } from 'ahooks'
import { Helmet } from 'react-helmet'
import api from '@/services'
import styles from './index.module.scss'

const { getProtocolDetail: getProtocolDetailRequest } = api

interface ProtocolDetail {
  name: string
  code: string
  content: string
}

const Protocol: React.FC = () => {
  const { code, gameCode } = useParams<{ code: string; gameCode: string }>()
  const [protocolDetail, setProtocolDetail] = useState<ProtocolDetail>({
    name: '',
    code: '',
    content: '',
  })

  const { loading, run: getProtocolDetail } = useRequest(
    () => getProtocolDetailRequest({ code, game_code: gameCode }),
    {
      manual: true,
      onSuccess(res) {
        setProtocolDetail(res.data)
      },
    }
  )

  useEffect(() => {
    getProtocolDetail()
  }, [getProtocolDetail])

  return (
    <>
      <Helmet>
        <title>{protocolDetail.name}</title>
      </Helmet>
      <div className={styles.ProtocalContainer}>
        <div className={styles.ProtocalHeader}>
          <div className={styles.Logo}></div>
        </div>
        <Spin spinning={loading}>
          <div className={styles.Title}>{protocolDetail.name}</div>
          <div
            className={styles.Content}
            dangerouslySetInnerHTML={{ __html: protocolDetail.content }}
          ></div>
        </Spin>
      </div>
    </>
  )
}

export default React.memo(Protocol)
