import { getUrlParams } from '@/utils'
import React from 'react'
import styles from './style.module.scss'

function Footer() {
  const urlParams = getUrlParams()
  let { hostname } = urlParams
  if (!hostname) {
    hostname = window.location.hostname
  }

  let beianNumber = '无'
  let companyName = '无'

  if (hostname.indexOf('huanle.com') > -1) {
    beianNumber = '沪ICP备13014419号'
    companyName = '上海欢乐互娱网络科技有限公司'
  }
  if (hostname.indexOf('www.huanle.com') > -1) {
    beianNumber = '沪ICP备13014419号-1'
    companyName = '上海欢乐互娱网络科技有限公司'
  }
  if (hostname.indexOf('123u.com') > -1) {
    beianNumber = '沪ICP备17003506号'
    companyName = '欢乐互娱（上海）科技股份有限公司'
  }
  if (hostname.indexOf('huanlehainan.com') > -1) {
    beianNumber = '琼ICP备16002924号-1'
    companyName = '海南欢乐互娱网络科技有限公司'
  }

  const date = new Date()
  const year = date.getFullYear()

  return (
    <div>
      <div className={styles.PCFooterContainer}>
        <div className={styles.Split}>
          <div className={styles.Right}>
            <ul className={styles.Nav}>
              <li>
                <a href="https://passport.huanle.com/html/useragreement.html">
                  隐私政策
                </a>
              </li>
              |
              <li>
                <a href="company">关于我们</a>
              </li>
              |
              <li>
                <a href="contact">联系我们</a>
              </li>
            </ul>
            <div className={styles.RigthItem}>
              <span>备案号：</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://beian.miit.gov.cn"
              >
                {beianNumber}
              </a>
            </div>
            <div className={styles.RightItem}>
              <span>文化部网络游戏举报网站：</span>
              <a target="_blank" rel="noreferrer" href="http://jbts.mct.gov.cn">
                jbts.mct.gov.cn
              </a>{' '}
              纠纷处理方式：联系客服或依《用户协议》约定方式处理&nbsp;&nbsp;
              家长监护工程专线电话:021-55698900
            </div>
            <div className={styles.RightItem}>
              Copyright © 2013- {year} All Rights Reserved&nbsp;{companyName}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
        <div className={styles.Tips}>
          抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
        </div>
      </div>
      <div className={styles.MobileFooterContainer}>
        <div className={styles.Item}>
          <a target="_blank" rel="noreferrer" href="http://beian.miit.gov.cn">
            备案号：{beianNumber}
          </a>
        </div>
        <div className={styles.Item}>
          Copyright © 2013- {year} All Rights Reserved&nbsp;
          {companyName}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Footer)
