import React from 'react'
import styles from './style.module.scss'

interface Props {
  visible?: boolean
  onClick?: () => void
}

function Tip(props: Props): JSX.Element {
  const { visible, onClick } = props
  return (
    <div
      className={`${styles.TipContainer} ${
        visible && styles.TipContainerVisible
      }`}
      onClick={onClick}
    >
      <img
        className={styles.ShareIndicator}
        src="https://tc-cdn.123u.com/official-website/recruit/share_indicator.png"
      />
      <p className={styles.Tips}>点击右上角发送给朋友</p>
    </div>
  )
}

Tip.defaultProps = {
  visible: false,
  onClick: () => {},
}

export default React.memo(Tip)
