import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss'

export default function TimeLine(props) {
  const { years } = props
  const [current, setCurrent] = useState(0)

  const addClassName = useCallback((dom, className) => {
    const names = dom.className.split(' ')
    if (names.indexOf(className) === -1) {
      names.push(className)
      dom.className = names.join(' ')
    }
  })

  const removeClassName = useCallback((dom, className) => {
    const names = dom.className.split(' ')
    const index = names.indexOf(className)
    if (index > -1) {
      names.splice(index, 1)
      dom.className = names.join(' ')
    }
  })

  useEffect(() => {
    // 移动时间线
    const wrapper = document.querySelector(`.${styles.SwiperWrapper}`)
    // 动态获取第一个节点的宽度
    let dom = document.querySelectorAll(`.${styles.TimelineNode}`)[0]
    let move = parseInt(window.getComputedStyle(dom).width, 10) * 2 * current
    wrapper.style.transform = `translateX(-${move}px)`

    // 移动文字
    const slider = document.querySelector(`.${styles.SwiperMilestoneSlider}`)
    // eslint-disable-next-line prefer-destructuring
    dom = document.querySelectorAll(`.${styles.MilestoneEvents}`)[0]
    move = parseInt(window.getComputedStyle(dom).width, 10) * current
    slider.style.transform = `translateX(-${move}px)`

    // 判断图标显隐
    const wrap = document.querySelector(`.${styles.SwiperMilestoneWrapper}`)
    const iconLeft = wrap.children[0]
    const iconRight = wrap.children[2]
    if (!iconLeft || !iconRight) {
      return
    }
    iconLeft.style.opacity = current === 0 ? 0 : 1
    iconRight.style.opacity = current === years.length - 1 ? 0 : 1
  }, [current])

  // 处理点击时间线事件
  const handleClickTimeLine = useCallback((index) => {
    if (index < 0 || index >= years.length) {
      return
    }
    const doms = document.querySelectorAll(`.${styles.TimelineNode}`)
    // 给当前节点添加class，上一次节点去掉class
    removeClassName(doms[current], `${styles.TimelineNodeActive}`)
    addClassName(doms[index], `${styles.TimelineNodeActive}`)
    setCurrent(index)
  })

  const itemLists = years.map((one, index) => (
    <div key={one.year.toString()} className={index === 0 ? `${styles.TimelineNode} ${styles.TimelineNodeActive}` : styles.TimelineNode} onClick={() => handleClickTimeLine(index)}>
      <span className={styles.TimelineNodeText}>{one.year}</span>
      <span className={styles.TimelineNodeDot}></span>
    </div>
  ))

  const itemLists2 = years.map((item) => (
    <div key={item.year} className={styles.MilestoneEvents}>
      <div>
        <TimeLineInfoBlock content={item.block1}></TimeLineInfoBlock>
      </div>
      <div>
        <TimeLineInfoBlock content={item.block2}></TimeLineInfoBlock>
      </div>
    </div>
  ))

  return (
    <div>
      <div className={styles.TimelineContainer}>
        <div className={styles.SwiperWrapper}>{itemLists}</div>
      </div>
      <section className={styles.SwiperMilestoneWrapper}>
        <img onClick={() => handleClickTimeLine(current - 1)} src="https://tc-cdn.123u.com/official-website/company/arrow_left2.png" draggable="false"></img>
        <div>
          <div className={styles.SwiperMilestoneSlider}>{itemLists2}</div>
        </div>
        <img onClick={() => handleClickTimeLine(current + 1)} src="https://tc-cdn.123u.com/official-website/company/arrow_right2.png" draggable="false"></img>
      </section>
    </div>
  )
}

function TimeLineInfoBlock(props) {
  const { content } = props
  return content.map((item) => (
    <div key={item.month} className={styles.MilestoneEvent}>
      <h5>{item.month}</h5>
      <p>{item.text}</p>
    </div>
  ))
}

TimeLine.propTypes = {
  years: PropTypes.arrayOf(PropTypes.object).isRequired,
}

TimeLineInfoBlock.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}
