import React from 'react'
import styles from './style.module.scss'

function Presentation() {
  const planList = [
    {
      img: 'https://tc-cdn.123u.com/official-website/recruit/plan1.png',
      time: '10月12日19点',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/recruit/plan2.png',
      time: '10月13日19点',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/recruit/plan3.png',
      time: '10月14日19点',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/recruit/plan4.png',
      time: '10月15日',
    },
  ]
  const sharerList = [
    {
      photo: 'https://tc-cdn.123u.com/official-website/recruit/steven.png',
      name: 'CEO：Steven',
      desc: '09年7月创办公司期待通过创新实现“技术+艺术”结合创造极致游戏体验并带来启发和思考',
      theme: '《欢乐价值观》',
    },
    {
      photo: 'https://tc-cdn.123u.com/official-website/recruit/dahei.png',
      name: '资深游戏制作人：大黑',
      desc: '一个懂得吃的游戏狂每周给团队一头烤全羊<br/>“大黑在此，谁敢放肆”',
      theme: '《欢乐价值观》',
    },
  ]
  return (
    <>
      <div className={styles.Plan}>
        <div className={styles.PlanTitle}>
          宣讲会安排
        </div>
        <div className={styles.PlanList}>
          {
            planList.map((e) => (
              <div className={styles.PlanItem}>
                <img src={e.img} alt="" />
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.Share}>
        <div className={styles.ShareTitle}>
          大咖分享
        </div>
        <div className={styles.SharerList}>
          {sharerList.map((e) => (

            <div className={styles.ShareItem}>
              <div className={styles.Left}>
                <div className={styles.SharerPhoto}>
                  <img src={e.photo} alt="" />
                </div>
                <div className={styles.SharerName}>
                  {e.name}
                </div>
              </div>
              <div className={styles.Right}>
                <div className={styles.SharerDesc} dangerouslySetInnerHTML={{ __html: e.desc }}>
                </div>
                <div className={styles.Theme}>
                  分享主题<br />
                  {e.theme}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.Train}>
        <div className={styles.TrainTitle}>
          全方位培训体系
        </div>
        <div className={styles.TrainDesc}>
          欲知详情，请莅临宣讲会
        </div>
        <div className={styles.TrainDetail}>
          <img src="https://tc-cdn.123u.com/official-website/recruit/train.jpg" />
        </div>
      </div>
      <div className={styles.Benefits}>
        <div className={styles.BenefitsTitle}>
          福利待遇
        </div>
        <div className={styles.BenefitsDetail}>
          <div className={styles.BenefitsList}>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div className={styles.Desc}>一日三餐免费吃；水果、咖啡饮料无限供应，你来就完全被公司包养</div>
            </div>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div>以后每年都记得带者爸爸妈妈去体检把，这钱公司掏</div>
            </div>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div className={styles.Desc}>过年期间长达12天的年假，再也不用赶春运大潮了</div>
            </div>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div className={styles.Desc}>想来我们公司面试却身在外地，别犹豫。交通和住宿我们包了</div>
            </div>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div className={styles.Desc}>过节和生日都会给你惊喜</div>
            </div>
            <div className={styles.BenefitsItem}>
              <div className={styles.icon}></div>
              <div className={styles.Desc}>还有多个不同的运动俱乐部，毕竟身体是精神的圣殿</div>
            </div>
          </div>
          <div className={styles.BenefitsImgWrap}>
            <img src="https://tc-cdn.123u.com/official-website/recruit/activity-benefit.png" />
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Presentation)
