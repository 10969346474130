import React, { memo } from 'react'
import styles from './style.module.scss'

function NewsHead() {
  return (
    <div className={styles.NewsMainTitle}>
      <div className={styles.Title}>新闻动态</div>
      <div className={styles.SubTitle}>News</div>
    </div>
  )
}

export default memo(NewsHead)
